<template>
  <b-card
    v-if="$can('read', 'users')"
    no-body
  >
    <b-card-header class="pb-50">
      <h5>
        Permissions
      </h5>
    </b-card-header>
    <b-table
      striped
      responsive
      :items="permissionsData"
      class="mb-0"
    >

      <template #cell(module)="data">
        {{ data.value }}
      </template>
      <template #cell()="data">
        <b-form-checkbox
          disabled
          :checked="data.value.value"
        />
      </template>

    </b-table>
  </b-card>
</template>

<script>
import { getUserData } from '@/auth/utils'
import {
  BCard, BTable, BCardHeader, BFormCheckbox,
} from 'bootstrap-vue'
import store from '@/store'

export default {
  components: {
    BCard, BTable, BFormCheckbox, BCardHeader,
  },
  props: {
    userData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      selected: [],
      theUserData: this.userData,
      permissionsData: [],
      showPermissions: false,
    }
  },
  setup() {
    const loggedInUserData = getUserData()

    return {
      loggedInUserData,
    }
  },
  created() {
    console.log('created')
    console.log(this.theUserData)
    this.setPermissionsData(this.theUserData.abilities)
  },
  methods: {
    setPermissionsData(data) {
      console.log('this.userData')
      console.log(this.userData)
      this.theUserData = this.userData
      const abilitiesOptions = store.state.appSettings.abilities_options
      // Remove Developer abilities if in staging or production
      if (process.env.VUE_APP_ENVIRONMENT_NAME === 'staging' || process.env.VUE_APP_ENVIRONMENT_NAME === 'production') {
        abilitiesOptions.forEach((item, index) => {
          if (item.subject === 'developer') {
            abilitiesOptions.splice(index, 1)
          }
        })
      }
      let tmp = {}
      this.permissionsData = []
      this.selected = []

      abilitiesOptions.forEach(item => {
        if (item.managedby.includes(this.loggedInUserData.group)) {
          this.showPermissions = true
          tmp = { module: item.title }
          tmp.read = {
            name: `${item.subject}-read`, value: `${item.subject}-read-false`, checkedvalue: true, uncheckedvalue: `${item.subject}-read-false`, disabledState: false,
          }
          // Restricting client create to only Management
          if (item.subject === 'clients' && this.userData.role === 'Management') {
            tmp.create = {
              name: `${item.subject}-create`, value: false, checkedvalue: true, uncheckedvalue: false, disabledState: false,
            }
          } else if (item.subject === 'clients') {
            tmp.create = {
              name: `${item.subject}-create`, value: false, checkedvalue: true, uncheckedvalue: false, disabledState: true,
            }
          } else {
            tmp.create = {
              name: `${item.subject}-create`, value: false, checkedvalue: true, uncheckedvalue: false, disabledState: false,
            }
          }

          tmp.update = {
            name: `${item.subject}-update`, value: false, checkedvalue: true, uncheckedvalue: false, disabledState: false,
          }
          // Restricting client and cameras delete to only Management
          if ((item.subject === 'cameras' || item.subject === 'clients') && this.userData.role === 'Management') {
            tmp.delete = {
              name: `${item.subject}-delete`, value: false, checkedvalue: true, uncheckedvalue: false, disabledState: false,
            }
          } else if (item.subject === 'cameras' || item.subject === 'clients') {
            tmp.delete = {
              name: `${item.subject}-delete`, value: false, checkedvalue: true, uncheckedvalue: false, disabledState: true,
            }
          } else {
            tmp.delete = {
              name: `${item.subject}-delete`, value: false, checkedvalue: true, uncheckedvalue: false, disabledState: false,
            }
          }
          // Populate with current users permissions
          if (typeof data !== 'undefined' && data.length > 0) {
            // console.log('Populate')
            // console.log(data)
            data.forEach(item2 => {
              if (item2.subject === item.subject) {
                item2.actions.forEach(item3 => {
                  if (item3.action === 'read') {
                    // if (item.subject === 'cameras') {
                    //   tmp.read = {
                    //     name: `${item.subject}-read`, value: true, checkedvalue: true, uncheckedvalue: `${item.subject}-read-false`, disabledState: true,
                    //   }
                    // } else {
                    //   tmp.read = {
                    //     name: `${item.subject}-read`, value: true, checkedvalue: true, uncheckedvalue: `${item.subject}-read-false`, disabledState: false,
                    //   }
                    // }
                    tmp.read = {
                      name: `${item.subject}-read`, value: true, checkedvalue: true, uncheckedvalue: `${item.subject}-read-false`, disabledState: false,
                    }
                    this.selected.push(true)
                  } else if (item3.action === 'create') {
                    tmp.create = {
                      name: `${item.subject}-create`, value: true, checkedvalue: true, uncheckedvalue: false, disabledState: false,
                    }
                    this.selected.push(true)
                  } else if (item3.action === 'update') {
                    tmp.update = {
                      name: `${item.subject}-update`, value: true, checkedvalue: true, uncheckedvalue: false, disabledState: false,
                    }
                    this.selected.push(true)
                  } else if (item3.action === 'delete') {
                    tmp.delete = {
                      name: `${item.subject}-delete`, value: true, checkedvalue: true, uncheckedvalue: false, disabledState: false,
                    }
                    this.selected.push(true)
                  }
                })
              }
            })
          }
          // Filter out non-applicable permissions for client viewer and client admins
          if ((this.theUserData.cognito_group === 'Client_Admin' || this.theUserData.cognito_group === 'Client_Viewer')
            && item.internal === true) {
            // Do not add this permission
          } else if (this.theUserData.cognito_group === 'Client_Admin' || this.theUserData.cognito_group === 'Client_Viewer') {
            // Filter out permissions if Client User and add permission
            // No Delete for any
            tmp.delete = {
              name: `${item.subject}-delete`, value: false, checkedvalue: true, uncheckedvalue: false, disabledState: true,
            }
            // Restrict Create
            if (item.subject !== 'users' && item.subject !== 'parkingpermit') {
              tmp.create = {
                name: `${item.subject}-create`, value: false, checkedvalue: true, uncheckedvalue: false, disabledState: true,
              }
            }
            // Restrict Update
            if (item.subject === 'users' || item.subject === 'clients' || item.subject === 'parkingpermit') {
              tmp.update = {
                name: `${item.subject}-update`, value: false, checkedvalue: true, uncheckedvalue: false, disabledState: false,
              }
            } else {
              tmp.update = {
                name: `${item.subject}-update`, value: false, checkedvalue: true, uncheckedvalue: false, disabledState: true,
              }
            }
            this.permissionsData.push(tmp)
          } else {
            this.permissionsData.push(tmp)
          }
        }
      })
      // console.log('this.permissionsData')
      // console.log(this.permissionsData)
    },
  },
}
</script>

<style>

</style>
