<template>
  <div v-if="$can('read', 'users')">
    <!-- Breadcrumb -->
    <b-row
      v-if="userData"
      class="content-header"
    >
      <b-col
        class="content-header-left mb-2"
        cols="12"
        md="9"
      >
        <b-row class="breadcrumbs-top">
          <b-col cols="12">
            <h2 class="content-header-title float-left pr-1 mb-0">
              Users
            </h2>
            <div class="breadcrumb-wrapper">
              <b-breadcrumb>
                <b-breadcrumb-item to="/apps/reports/type">
                  <feather-icon
                    icon="HomeIcon"
                    size="16"
                    class="align-text-top"
                  />
                </b-breadcrumb-item>
                <b-breadcrumb-item
                  to="/apps/users/list"
                >
                  Users
                </b-breadcrumb-item>
                <b-breadcrumb-item
                  active
                >
                  {{ userData.username }}
                </b-breadcrumb-item>
              </b-breadcrumb>
            </div>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <!-- Alert: No item found -->
    <b-alert
      variant="danger"
      :show="userData === undefined"
    >
      <h4 class="alert-heading">
        Error fetching user data
      </h4>
      <div class="alert-body">
        No user found with this username. Check
        <b-link
          class="alert-link"
          :to="{ name: 'apps-users-list'}"
        >
          User List
        </b-link>
        .
      </div>
    </b-alert>

    <template v-if="userData">
      <!-- First Row -->
      <b-row>
        <b-col
          cols="24"
          xl="12"
          lg="12"
          md="12"
        >
          <user-view-user-info-card :user-data="userData" />
        </b-col>
      </b-row>

      <b-row>
        <b-col
          cols="24"
          xl="12"
          lg="12"
          md="12"
        >
          <user-view-user-permissions-card :user-data="userData" />
        </b-col>
      </b-row>

      <!-- <b-row> -->
      <!-- Chat -->
      <!-- <b-col
      lg="6"
      > -->
      <!-- <card-advance-chat /> -->
      <!-- </b-col> -->
      <!-- Timeline -->
      <!-- <b-col
      lg="6"
      > -->
      <!-- <user-view-user-timeline-card /> -->
      <!-- </b-col> -->
      <!-- </b-row> -->

      <!-- <calendar /> -->
    </template>

  </div>
</template>

<script>
import store from '@/store'
import router from '@/router'
import { ref, onUnmounted } from '@vue/composition-api'
import {
  BRow, BCol, BAlert, BLink, BBreadcrumb, BBreadcrumbItem,
} from 'bootstrap-vue'

// import InvoiceList from '@/views/apps/invoice/invoice-list/InvoiceList.vue'
// import Calendar from '@/views/apps/calendar/Calendar.vue'
// import CardAdvanceChat from '@/views/card/card-advance/CardAdvanceChat.vue'
import userStoreModule from '../userStoreModule'
import UserViewUserInfoCard from './UserViewUserInfoCard.vue'
// import UserViewUserTimelineCard from './UserViewUserTimelineCard.vue'
import UserViewUserPermissionsCard from './UserViewUserPermissionsCard.vue'
import ability from '../../../../libs/acl/ability'

export default {
  components: {
    BRow,
    BCol,
    BAlert,
    BLink,
    BBreadcrumb,
    BBreadcrumbItem,

    // Local Components
    UserViewUserInfoCard,
    // UserViewUserTimelineCard,
    // CardAdvanceChat,
    UserViewUserPermissionsCard,

    // InvoiceList,
    // Calendar,
  },
  setup() {
    const userData = ref(null)

    const USER_APP_STORE_MODULE_NAME = 'app-user'

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, userStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
    })
    if (ability.can('read', 'users')) {
      store.dispatch('app-user/fetchUser', router.currentRoute.params.username)
        .then(response => { userData.value = response.data })
        .catch(error => {
          console.log(error)
          if (typeof error.response !== 'undefined' && error.response.status === 404) {
            userData.value = undefined
          }
        })
    }

    return {
      userData,
    }
  },
}
</script>

<style>

</style>
